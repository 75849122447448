import React, {
  ChangeEvent, ComponentProps, forwardRef,
} from 'react';

import { RadioButtonRoot, RadioButtonInput, LoaderLayout } from './index.styled';
import Loader from '../Loader';

export type LabelPosition = 'right' | 'left';

interface RadioButtonProps extends ComponentProps<'input'> {
  id: string;
  label?: string;
  inverted?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  labelPosition?: LabelPosition,
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(({
  label,
  className,
  loading,
  labelPosition = 'right',
  style,
  ...props
}, ref) => (
  <RadioButtonRoot className={className} labelPosition={labelPosition} style={style}>
    {
      loading
        ? (
          <LoaderLayout>
            <Loader />
          </LoaderLayout>
        )
        : <RadioButtonInput type="radio" {...props} ref={ref} />
    }
    {label}
  </RadioButtonRoot>
));

export default RadioButton;
