import React, { FunctionComponent, HTMLProps } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import { Tooltip } from 'ui-kit';
import { ChatIcon } from 'ui-kit/assets/icons';

import Layout from './SupportButton.styled';

const SupportButton: FunctionComponent<Omit<HTMLProps<HTMLDivElement>, 'ref'>> = observer(({
  ...restProps
}) => {
  const { t } = useTranslation();
  const { rootStore: { carrotQuest }, spaceStore: { space } } = useStores();
  const { isReady } = carrotQuest;

  const handleClick = () => {
    carrotQuest.openChat(space?.id);
  };

  if (carrotQuest.isDisabled()) {
    return null;
  }

  return (
    <Tooltip
      title={t('common.support')}
      component={(
        <Layout
          id="plans-support"
          data-stats="support"
          disabled={!isReady}
          variant="primary"
          size="large"
          onClick={handleClick}
          {...restProps}
        >
          <ChatIcon />
        </Layout>
      )}
    />
  );
});

export default SupportButton;
