import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';
import { throttle } from 'throttle-debounce';
import isMobile from 'is-mobile';
import { AxiosError } from 'axios';
import { eventBus } from 'mobx-event-bus2';
import Client from '@livedigital/client';
import type Peer from '@livedigital/client/dist/engine/Peer';

import {
  CreateParticipantPayload,
  NetworkScores,
  UpdateParticipantEventPayload,
} from 'types/stores/participant';
import {
  getClientUniqueId,
  getIsHandRaised,
} from 'helpers/common';
import {
  isChromeBasedBrowser,
  isHeadlessChrome,
  isPermissionErrorText,
  isSafari,
} from 'helpers/browser';
import { getAudioEncodings, getVideoConfig } from 'helpers/media';
import { getLoadBalancerUrl } from 'helpers/url';
import { isValidHttpUrl, searchParamsToObject } from 'helpers/parseURL';
import { MessageBatcher } from 'message-batcher';
import { CLIENT_EVENTS } from '@livedigital/client/dist/constants/events';
import {
  ActivityConfirmationRequiredPayload,
  AvailableMediaDevices, Role,
  TrackForceClosedPayload,
} from '@livedigital/client/dist/types/common';
import {
  Track,
  AudioTrack,
  VideoTrack,
  VideoTrackPublishParams,
} from '@livedigital/client/dist/types/media';
import {
  MosConnectionQuality,
  NOISE_SUPPRESSION_LS_KEY,
} from 'constants/common';
import { JoinApproval, OutboundConnectionQuality } from 'types/stores/peerAppData';
import ParticipantAppDataStore from 'stores/participantAppData';
import { VideoConfig, EncoderConfig } from 'helpers/types';
import UrlQueryParams from 'modules/UrlQueryParams';
import ParticipantInfo from 'modules/ParticipantInfo';
import UserSettings from 'modules/UserSettings';
import RoomPermissions from 'modules/RoomPermissions';

import PeerStore from './peer';
import { RootStore } from './root';
import DeviceStore from './device';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/localStorage';
import {
  ParticipantResponse,
  PeerRole,
  RoomRole,
  TrackLabel,
  TransformParams,
} from '../services/MoodHoodApiClient/types';
import logger, { castToKnownLogLevel } from '../helpers/logger';
import {
  CommonToastType,
  MoodhoodPeer,
  ReJoinReason,
  RoomEvent,
  RoomEventNewReactionPayload,
  RoomEventNewRestrictionsPayload,
  RoomType,
  WaitingRoomAudience,
} from '../types/common';
import { ChannelEvent, SocketIOEvents } from '../types/socket';
import { NetworkMetric } from '../services/MoodHoodAnalyticsApiClient/types';

class ParticipantStore {
  rootStore: RootStore;

  client: Client;

  deviceStore: DeviceStore;

  participantInfo: ParticipantInfo;

  id?: string;

  externalUserId?: string;

  @observable audioDisabled = true;

  @observable videoDisabled = true;

  @observable isScreenSharingDisabled = true;

  @observable isDrone = false;

  @observable isNoChat = false;

  @observable videoIncline = 0;

  @observable videoFlipH = true;

  @observable videoFlipV = false;

  @observable isMobile = false;

  @observable isModerator = false;

  @observable isUserPermissionsFetched = false;

  @observable isRecorder = false;

  @observable isEnableVideoLock = false;

  @observable isEnableAudioLock = false;

  @observable isEnableScreenSharingLock = false;

  @observable lastProducerResumeTime = 0;

  @observable isAudioStreamingAllowed = true;

  @observable isVideoStreamingAllowed = true;

  @observable cameraTrack?: VideoTrack;

  @observable cameraPreview?: VideoTrack;

  @observable microphoneTrack?: AudioTrack;

  @observable screenVideoTrack?: VideoTrack;

  @observable screenAudioTrack?: AudioTrack;

  @observable clientUniqueId: string | null;

  @observable inboundMOS = {
    time: Date.now(),
    value: MosConnectionQuality.GOOD,
  };

  @observable outboundMOS = {
    time: Date.now(),
    value: MosConnectionQuality.GOOD,
  };

  redirectURL = '';

  private webrtcIssuesBatcher: MessageBatcher;

  private networkScoresBatcher: MessageBatcher;

  @observable participantAppData: ParticipantAppDataStore;

  @observable videoConfig: VideoConfig = getVideoConfig();

  @observable isScreenSharingChanging = false;

  /* TBD: Should be moved to user store */
  @observable userSettings = new UserSettings();

  @observable private _roomPermissions: RoomPermissions | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.participantInfo = new ParticipantInfo({ userStore: rootStore.userStore });
    this.deviceStore = new DeviceStore(this);
    this.isMobile = isMobile();
    this.clientUniqueId = getClientUniqueId();
    this.client = new Client({
      network: {
        loadbalancer: {
          baseURL: getLoadBalancerUrl(),
        },
      },
      logLevel: 7,
      effectsSDKParams: {
        customerId: process.env.REACT_APP_EFFECTS_SDK_CUSTOMER_ID || '',
      },
      onIssues: (issues: { type: string, reason: string }[]) => {
        if (this.role === PeerRole.Audience) {
          // just to reduce the number of logs
          return;
        }

        issues.forEach((issue) => {
          this.rootStore.debugStore.pushWebrtcIssueType(issue.type);
          this.webrtcIssuesBatcher.Queue({ ...issue, clientTimestamp: new Date() });
        });
      },
      onNetworkScoresUpdated: (scores) => {
        this.handleUpdatedNetworkScores(scores);
      },
      onLogMessage: (msg: string, meta: Record<string, unknown> = {}, ...args: unknown[]) => {
        const defaultLvl = 'warn';
        const logLevel = args.length ? castToKnownLogLevel(String(args[args.length - 1] || defaultLvl)) : defaultLvl;
        /* TBD: roomStore might not be constructed at this point */
        if (this.rootStore.roomStore?.isWebinar && logLevel !== 'error') {
          // just to reduce the number of logs
          return;
        }

        this.rootStore.debugStore?.pushSDKLogLevel(logLevel);
        if (logger[logLevel]) {
          logger[logLevel](msg, meta);
        } else {
          logger.warn(msg, meta);
        }
      },
    });

    this.client.initEffectsSDK();
    this.audioDisabled = getLocalStorageItem<boolean>('audioDisabled') || this.audioDisabled;
    this.videoDisabled = getLocalStorageItem<boolean>('videoDisabled') || this.videoDisabled;
    makeAutoObservable(this);
    this.initVideoTransform();
    this.listenChannelEvents();
    this.webrtcIssuesBatcher = new MessageBatcher({ MaxBatchSize: 50, MaxDelay: 60000, MinDelay: 30000 });
    this.webrtcIssuesBatcher.on('batch', (issues: { type: string, reason: string }[]) => {
      this.handleWebrtcIssues(issues);
    });
    this.networkScoresBatcher = new MessageBatcher({ MaxBatchSize: 100, MaxDelay: 60000, MinDelay: 30000 });
    this.networkScoresBatcher.on('batch', (metrics: NetworkMetric[]) => {
      this.handleNetworkMetrics(metrics);
    });

    this.participantAppData = new ParticipantAppDataStore();
  }

  @action setVideoConfig(config: VideoConfig): void {
    this.videoConfig = config;
  }

  @observable get cameraPreviewTrack(): VideoTrack | undefined {
    return this.cameraPreview;
  }

  @action listenChannelEvents(): void {
    this.client.observer.on(CLIENT_EVENTS.peerJoined, (peer: MoodhoodPeer) => {
      if (peer.role !== PeerRole.Host) {
        return;
      }

      const { peer: myPeer } = this;
      if (myPeer && !myPeer.isJoinApproved) {
        return;
      }

      if (this.rootStore.spaceStore.hasPeer(peer.id)) {
        return;
      }

      const newPeer = new PeerStore({
        peer,
        roomStore: this.rootStore.roomStore,
      });

      // Mobile apps run a child process for screensharing purposes.
      // The child process connects as independent peer with same participantId so
      // we need to copy participant info from parent peer
      const peerOfSameParticipant = this
        .rootStore
        .roomStore
        .participantPeers.find((p) => p.participantId === peer.uid);

      if (peerOfSameParticipant) {
        newPeer.copyParticipantInfo(peerOfSameParticipant);
      }

      this.rootStore.spaceStore.setPeer(newPeer);
    });

    this.client.observer.on(CLIENT_EVENTS.peerLeft, (peerId: unknown) => {
      if (peerId && typeof peerId === 'string') {
        this.rootStore.spaceStore.deletePeer(peerId);
        return;
      }

      logger.error('Peer left error: invalid peerId');
    });

    this.client.observer.on(CLIENT_EVENTS.channelEvent, (event: RoomEvent) => {
      this.handleRoomEvents(event);
    });

    this.client.observer.on(SocketIOEvents.Disconnected, async (payload: unknown) => {
      logger.debug('Handling client "disconnect" event', { payload });

      if (typeof payload !== 'object') {
        return;
      }

      const eventPayload = (payload || {}) as Record<string, unknown>;
      const { code } = eventPayload;

      // check if it's a force disconnect from server side
      if (code !== 'server.disconnect') {
        return;
      }

      await this.rootStore.uiStore.leaveRoom();
    });

    this.client.observer.on(CLIENT_EVENTS.devicesListUpdated, (devices: AvailableMediaDevices) => {
      this.deviceStore.handleDeviceListUpdated(devices);
    });

    this.client.observer.on(CLIENT_EVENTS.channelRejoinRequired, this.handleRejoinRequired.bind(this));

    this.client.observer.on(SocketIOEvents.Reconnecting, () => {
      this.rootStore.roomStore.setIsConnectionLost(true);
    });

    this.client.observer.on(SocketIOEvents.Connected, () => {
      this.rootStore.roomStore.setIsConnectionLost(false);
    });

    this.client.observer.on(CLIENT_EVENTS.transportConnectionTimeout, ({ reason }: { reason: 'ice' | 'dtls' }) => {
      if (reason === 'ice') {
        this.rootStore.uiStore.setIsAntivirusBlocksConnectionWarningOpen(true);
      }

      if (reason === 'dtls') {
        if (!this.client.getPreferRelay()) {
          this.client.setPreferRelay(true);
          this.handleRejoinRequired();
        } else {
          this.rootStore.uiStore.setIsFirewallBlocksConnectionWarningOpen(true);
        }
      }
    });

    this.client.observer.on(CLIENT_EVENTS.activityConfirmationRequired, ({
      time,
    }: ActivityConfirmationRequiredPayload) => {
      const timeout = (time / 1000) - 1;
      this.rootStore.uiStore.setTimeoutForParticipantInRoomDialog(timeout);
    });

    this.client.observer.on(CLIENT_EVENTS.activityConfirmationAcquired, () => {
      this.rootStore.uiStore.setTimeoutForParticipantInRoomDialog(0);
    });

    this.client.observer.on(CLIENT_EVENTS.activityConfirmationExpired, () => {
      this.rootStore.uiStore.leaveRoom();
    });

    this.client.observer.on(CLIENT_EVENTS.activeSpeakerChanged, ({ peer }: { peer?: Peer }) => {
      eventBus.post(CLIENT_EVENTS.activeSpeakerChanged, { peer });
    });

    this.client.observer.on(CLIENT_EVENTS.trackForceClosed, ({ label }: TrackForceClosedPayload) => {
      switch (label) {
        case TrackLabel.Camera: {
          this.rootStore.uiStore.setCommonToast({
            message: 'warnings.adminTurnedOffCamera',
            type: CommonToastType.Warn,
          });
          break;
        }
        case TrackLabel.Microphone: {
          /* TBD: Implement proper handling of track 'ended' state */
          /* Quick fix to force track creation */
          this.rootStore.participantStore.setMicrophoneTrack();

          this.rootStore.uiStore.setCommonToast({
            message: 'warnings.adminTurnedOffMicrophone',
            type: CommonToastType.Warn,
          });
          break;
        }
        case TrackLabel.ScreenVideo: {
          this.rootStore.uiStore.setCommonToast({
            message: 'warnings.adminTurnedOffScreenSharing',
            type: CommonToastType.Warn,
          });
          break;
        }
        default:
          break;
      }
    });

    this.client.observer.on(SocketIOEvents.Error, ({ error }) => {
      if (error?.data?.errorCode === 'unauthorized') {
        this.rootStore.uiStore.setCommonToast('errors.authenticationError.text');
      }
    });
  }

  @action async handleRoomEvents(event: RoomEvent):Promise<void> {
    const { roomStore, debugStore } = this.rootStore;
    switch (event.eventName) {
      case ChannelEvent.UpdateParticipant: {
        await this.handleUpdateParticipant(event.data);
        break;
      }
      case ChannelEvent.NewReaction: {
        roomStore.handleNewReaction(event.data as RoomEventNewReactionPayload);
        break;
      }
      case ChannelEvent.RoomRecord: {
        roomStore.handleRoomRecord(event.data);
        break;
      }
      case ChannelEvent.UpdateRoomParticipantsCount: {
        roomStore.handleUpdateRoomParticipantsCount(event.data);
        break;
      }
      case ChannelEvent.NeedToSendDumps: {
        debugStore.handleNeedSendDumps(event.data);
        break;
      }
      case ChannelEvent.AnnouncementsUpdated:
      case ChannelEvent.DemonstrationsUpdated:
      case ChannelEvent.BreakoutRoomsSessionEvent: {
        eventBus.post(event.eventName, event.data);
        break;
      }

      case ChannelEvent.SwitchOffParticipantsMedia: {
        roomStore.handleSwitchOffParticipantsMedia(event.data);
        break;
      }

      case ChannelEvent.RestrictionsUpdated: {
        roomStore.calls.updateRestrictionsByEvent(event.data as RoomEventNewRestrictionsPayload);
        break;
      }

      default:
        logger.warn('Unhandled custom event', {
          case: 'handleRoomEvents',
          event,
        });
        break;
    }
  }

  @action async handleUpdateParticipant(payload: UpdateParticipantEventPayload): Promise<void> {
    const { roomStore } = this.rootStore;
    const {
      participantId,
      roomId,
    } = payload;

    const peers = roomStore.participantPeers.filter((item) => item.participantId === participantId);
    const handlers = peers.map(async (peer) => {
      if (Object.getOwnPropertyDescriptor(payload, 'roomId')) {
        if (peer.isMe) {
          if (!roomId) {
            await this.rootStore.uiStore.leaveRoom();
            return;
          }

          if (roomId !== peer.roomId) {
            try {
              const {
                room: { appId, channelId },
              } = await this.rootStore.moodHoodApiClient.room.getRoomById(peer.spaceId, roomId);
              this.rootStore.roomStore.appId = appId;
              this.rootStore.roomStore.channelId = channelId;
              this.rootStore.roomStore.setId(roomId);
              this.rootStore.roomStore.isMovingParticipant = true;
              await this.rootStore.roomStore.reJoinParticipant(ReJoinReason.Move);
              this.rootStore.roomStore.isMovingParticipant = false;
            } catch (error) {
              logger.error('Failed to join participant to another room', {
                peerId: this.rootStore.participantStore.client.id,
                case: 'Move participant to another room',
                error,
              });
            }
          }
        }
      }
    });

    await Promise.all(handlers);
  }

  @action initVideoTransform():void {
    const savedIncline = getLocalStorageItem<number>('incline');
    if (savedIncline !== null) {
      this.setVideoIncline(savedIncline);
    }

    const savedFlipH = getLocalStorageItem<boolean>('fliph');
    if (savedFlipH !== null) {
      this.setVideoFlipH(savedFlipH);
    }

    const savedFlipV = getLocalStorageItem<boolean>('flipv');
    if (savedFlipV !== null) {
      this.setVideoFlipV(savedFlipV);
    }
  }

  @action async createCameraTrack(): Promise<VideoTrack | null> {
    if (this.cameraTrack) {
      return this.cameraTrack;
    }

    if (!this.deviceStore.availableVideoDevices.length) {
      logger.error('Failed to create camera track', {
        error: 'No available video device',
        peerId: this.client.id,
        case: 'createCameraTrack',
      });

      return null;
    }

    try {
      const cameraTrack = await this.client.createCameraVideoTrack({
        video: {
          ...this.cameraTrackDefaultConstrains,
          deviceId: this.deviceStore.currentVideoDeviceId,
        },
        encoderConfig: this.cameraEncoderConfig,
        stopTrackOnPause: true,
        effects: this.isVisualEffectsEnabled,
      });

      const { effects } = this.userSettings;

      if (effects) {
        await cameraTrack.applyEffects(effects);
      }

      this.deviceStore.resetCameraError();
      this.cameraTrack = cameraTrack;
      const { mediaStreamTrack } = this.cameraTrack;

      const handleEnded = () => {
        this.cameraTrack = undefined;
        this.videoDisabled = true;
      };

      mediaStreamTrack.addEventListener('ended', handleEnded, { once: true });

      return this.cameraTrack;
    } catch (error) {
      this.deviceStore.setCameraError((error as Error).message);
      logger.error('Failed to create camera track', {
        error,
        peerId: this.client.id,
        case: 'createCameraTrack',
      });

      return null;
    }
  }

  async waitCameraLocker(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.isEnableVideoLock) {
        resolve();
        return;
      }
      const interval = setInterval(() => {
        if (!this.isEnableVideoLock) {
          clearInterval(interval);
          resolve();
        }
      }, 500);
    });
  }

  @action async createCameraPreviewTrack(): Promise<VideoTrack | null> {
    await this.waitCameraLocker();

    if (this.cameraPreview) {
      return this.cameraPreview;
    }

    const { currentVideoDeviceId: deviceId } = this.deviceStore;
    const devices = this.deviceStore.availableVideoDevices.map((device) => ({
      deviceId: device.deviceId,
      label: device.label,
    }));

    if (!this.deviceStore.canAccessVideoDevice) {
      logger.warn('Failed to create camera preview track', {
        devices,
        deviceId,
        error: 'No access to video devices',
        peerId: this.client.id,
        case: 'createCameraPreviewTrack',
      });

      return null;
    }

    try {
      this.setIsEnableVideoLock(true);
      const cameraPreviewTrack = await this.client.createCameraVideoTrack({
        video: {
          ...this.cameraTrackDefaultConstrains,
          deviceId,
        },
        encoderConfig: this.cameraEncoderConfig,
        stopTrackOnPause: true,
        effects: this.isVisualEffectsEnabled,
        isPreview: true,
      });

      cameraPreviewTrack.mediaStreamTrack.addEventListener('ended', () => {
        if (this.cameraPreview && !this.isEnableVideoLock
          && !this.rootStore.roomStore.isRoomReJoining
          && !this.rootStore.uiStore.isLeavingRoom) {
          this.deviceStore.setCameraError('Unexpected track end due device failure or permission denial');
        }
      });

      this.setCameraPreviewTrack(cameraPreviewTrack);
      const { effects } = this.userSettings;
      if (effects) {
        await cameraPreviewTrack.applyEffects(effects);
      }

      this.deviceStore.resetCameraError();

      return cameraPreviewTrack;
    } catch (error) {
      logger.error('Failed to create camera preview track', {
        error,
        devices,
        deviceId,
        peerId: this.client.id,
        case: 'createCameraPreviewTrack',
      });

      this.deviceStore.setCameraError((error as Error).message);
      return null;
    } finally {
      this.setIsEnableVideoLock(false);
    }
  }

  @action async toggleCameraPreview() {
    await (this.cameraPreviewTrack ? this.deleteCameraPreviewTrack() : this.createCameraPreviewTrack());
  }

  @action async createMicrophoneAudioTrack(): Promise<AudioTrack | null> {
    if (this.microphoneTrack) {
      return this.microphoneTrack;
    }

    const { currentAudioDeviceId: deviceId } = this.deviceStore;
    const devices = this.deviceStore.availableAudioDevices.map((device) => ({
      deviceId: device.deviceId,
      label: device.label,
    }));

    if (!this.deviceStore.canAccessAudioDevice) {
      logger.warn('Failed to create audio track', {
        devices,
        deviceId,
        error: 'No access to audio devices',
        peerId: this.client.id,
        case: 'createMicrophoneAudioTrack',
        micError: this.deviceStore.microphoneError,
      });

      return null;
    }

    try {
      this.microphoneTrack = await this.client.createMicrophoneAudioTrack({
        audio: {
          deviceId: { exact: deviceId },
        },
        encoderConfig: getAudioEncodings(),
        noiseSuppression: this.isNoiseSuppressionEnabled,
      });

      this.deviceStore.resetMicrophoneError();

      const { mediaStreamTrack } = this.microphoneTrack;

      const handleEnded = () => {
        /* Most of the time track ends due hardware failure(i.e physically disconnected headset) */
        /* We keep dead mic track to allow auto mic select properly unpublish track */
        /* TBD: investigate cases when track ends to other reasons, and auto select is not triggered */
        this.audioDisabled = true;
        mediaStreamTrack.removeEventListener('ended', handleEnded);
      };

      mediaStreamTrack.addEventListener('ended', handleEnded);

      return this.microphoneTrack;
    } catch (error) {
      logger.error('Failed to create audio track', {
        error,
        devices,
        deviceId,
        peerId: this.client.id,
        case: 'createMicrophoneAudioTrack',
      });

      this.deviceStore.setMicrophoneError((error as Error).message);
      return null;
    }
  }

  @action async enableAudio(): Promise<void> {
    this.setIsEnableAudioLock(true);
    this.logAudioStatusChangeAttemptForNonHost(true);
    try {
      if (this.microphoneTrack?.isPublished) {
        await this.resumeAudio();
        if (this.isNoiseSuppressionEnabled) {
          try {
            await this.enableMicNoiseSuppression();
          } catch (error) {
            logger.warn('Failed to enable noise suppression on resume', {
              error,
              peerId: this.client.id,
              case: 'enableAudio',
            });
          }
        }

        return;
      }

      await this.publishNewMicrophoneTrack();
    } catch (error) {
      logger.error('Failed to enable audio', {
        error,
        peerId: this.client.id,
        case: 'enableAudio',
      });
      this.deviceStore.setMicrophoneError((error as Error).message);
      this.setAudioDisabled(true);
    } finally {
      this.setIsEnableAudioLock(false);
    }
  }

  @action async disableAudio(): Promise<void> {
    this.setIsEnableAudioLock(true);
    this.logAudioStatusChangeAttemptForNonHost(false);
    try {
      if (this.microphoneTrack?.isPublished) {
        if (this.isNoiseSuppressionEnabled) {
          try {
            await this.disableMicNoiseSuppression();
          } catch (error) {
            logger.warn('Failed to disable noise suppression on pause', {
              error,
              peerId: this.client.id,
              case: 'disableAudio',
            });
          }
        }

        await this.pauseAudio();
        return;
      }

      await this.unPublishMicrophoneTrack();
    } catch (error) {
      logger.error('Failed to disable audio', {
        error,
        peerId: this.client.id,
        case: 'disableAudio',
      });
    } finally {
      this.setIsEnableAudioLock(false);
    }
  }

  @action async enableVideo(): Promise<void> {
    await this.waitCameraLocker();

    this.setIsEnableVideoLock(true);

    try {
      if (!this.cameraTrack?.isPublished) {
        await this.publishNewCameraTrack();
        return;
      }

      await this.resumeVideo();
      await this.enableCameraEffectsOnResume();
    } catch (error) {
      logger.error('Failed to enable video', {
        error,
        peerId: this.client.id,
        case: 'enableVideo',
      });
      this.deviceStore.setCameraError((error as Error).message);
      this.setVideoDisabled(true);
    } finally {
      this.setIsEnableVideoLock(false);
    }
  }

  @action async disableVideo(): Promise<void> {
    this.setIsEnableVideoLock(true);
    try {
      if (this.cameraTrack?.isPublished) {
        await this.pauseVideo();
        return;
      }

      await this.unPublishCameraTrack();
    } catch (error) {
      logger.error('Failed to disable video', {
        error,
        peerId: this.client.id,
        case: 'disableVideo',
      });
    } finally {
      this.setIsEnableVideoLock(false);
    }
  }

  @action async toggleMicrophone(): Promise<void> {
    if (!this.deviceStore.canAccessAudioDevice) {
      logger.info('Skip toggling audio because has no access to audio devices', {
        participantId: this.id,
      });
      return;
    }

    if (this.audioDisabled) {
      await this.enableAudio();
    } else {
      await this.disableAudio();
    }

    setLocalStorageItem('audioDisabled', this.audioDisabled);
  }

  @action toggleCamera = throttle(500, () => this.toggleCameraWithoutThrottle());

  @action async toggleCameraWithoutThrottle(): Promise<void> {
    await this.waitCameraLocker();

    if (!this.peer?.isVideoStreamingAllowed) {
      return;
    }

    if (!this.deviceStore.canAccessVideoDevice) {
      logger.info('Skip toggling video because has no access to video devices', {
        participantId: this.id,
      });
      return;
    }

    logger.info('Toggle video in room', { on: !this.videoDisabled });

    if (this.videoDisabled) {
      await this.enableVideo();
    } else {
      await this.disableVideo();
    }

    setLocalStorageItem('videoDisabled', this.videoDisabled);
  }

  @action setIsDrone(isDrone: boolean): void {
    this.isDrone = isDrone;
  }

  @action setIsNoChat(isNoChat: boolean): void {
    this.isNoChat = isNoChat;
  }

  @action setVideoIncline(incline: number): void {
    if ([360, -360].includes(incline)) {
      this.videoIncline = 0;
    } else {
      this.videoIncline = incline;
    }

    setLocalStorageItem('incline', this.videoIncline);
  }

  @action setVideoFlipH(value: boolean): void {
    this.videoFlipH = value;
    setLocalStorageItem('fliph', value);
  }

  @action setVideoFlipV(value: boolean): void {
    this.videoFlipV = value;
    setLocalStorageItem('flipv', value);
  }

  @action setIsModerator(value: boolean): void {
    this.isModerator = value;
  }

  @action setIsUserPermissionsFetched(value: boolean): void {
    this.isUserPermissionsFetched = value;
  }

  @action setIsRecorder(value: boolean): void {
    this.isRecorder = value;
  }

  @computed get peer(): PeerStore | undefined {
    return this.rootStore.roomStore.myPeer;
  }

  @action async create(payload: CreateParticipantPayload): Promise<ParticipantResponse | null> {
    try {
      const participant = await this.rootStore.moodHoodApiClient.space.participants.create(payload);
      const externalUserId = participant.externalUserId || UrlQueryParams.userParams.externalUserId;
      this.setId(participant.id);
      this.setExternalUserId(externalUserId);
      this.updateUsername(participant.name);
      return participant;
    } catch (error) {
      logger.error('Failed to create participant', { ...payload, error });

      this.rootStore.roomStore.handleAxiosError(error as AxiosError);
      return null;
    }
  }

  @action async updateUsername(name: string): Promise<void> {
    this.participantInfo.setUsername(name);
    await this.peer?.appData.setName(this.participantInfo.username);
  }

  @action async updateImage(image: string | null): Promise<void> {
    await this.peer?.appData.setImage(image);
  }

  @action setId(id?: string): void {
    this.id = id;
    window.lsd.participantId = id;
  }

  @action setExternalUserId(id?: string): void {
    this.externalUserId = id;
  }

  @action setParticipantInfo(participantInfo: ParticipantInfo): void {
    this.participantInfo = participantInfo;
  }

  @computed get name() {
    return this.participantInfo.username;
  }

  @action setVideoStreamingPermission(value: boolean): void {
    this.isVideoStreamingAllowed = value;
  }

  @action setAudioStreamingPermission(value: boolean): void {
    this.isAudioStreamingAllowed = value;
  }

  @action setScreenVideoTrack(track?: VideoTrack): void {
    this.screenVideoTrack = track;
  }

  @action setScreenAudioTrack(track?: AudioTrack): void {
    this.screenAudioTrack = track;
  }

  @action async createScreenSharingMediaTracks(): Promise<Track[]> {
    try {
      const mediaTracks: Track[] = await this.client.createScreenMediaTracks({
        video: {
          ...this.screenSharingTrackDefaultConstrains,
        },
        audio: true,
        videoEncoderConfig: this.screenSharingEncoderConfig,
        audioEncoderConfig: getAudioEncodings(),
      });

      this.setScreenVideoTrack(mediaTracks.find((track) => track.kind === 'video') as VideoTrack);
      if (this.screenVideoTrack) {
        const { mediaStreamTrack } = this.screenVideoTrack;

        const handleScreenVideoEnded = async () => {
          if (this.isScreenSharingChanging) {
            return;
          }

          this.setScreenVideoTrack(undefined);
          this.setScreenAudioTrack(undefined);
          await this.disableScreensharing();
          this.setIsScreenSharingDisabled(true);
        };

        mediaStreamTrack.addEventListener('ended', handleScreenVideoEnded, { once: true });
      }

      this.setScreenAudioTrack(mediaTracks.find((track) => track.kind === 'audio') as AudioTrack);
      if (this.screenAudioTrack) {
        const { mediaStreamTrack } = this.screenAudioTrack;

        const handleScreenAudioEnded = () => {
          if (this.isScreenSharingChanging) {
            return;
          }

          this.setScreenAudioTrack(undefined);
        };

        mediaStreamTrack.addEventListener('ended', handleScreenAudioEnded, { once: true });
      }

      return mediaTracks;
    } catch (error) {
      logger.error('Failed to create screensharing media tracks', {
        error,
        peerId: this.client.id,
        participantId: this.id,
        case: 'createScreenSharingMediaTracks',
      });

      throw error;
    }
  }

  @action async enableScreensharing(): Promise<void> {
    if (this.isEnableScreenSharingLock || !this.peer) {
      return;
    }

    this.rootStore.uiStore.setScreensharingToggleError(undefined);
    const screenSharingSlot = this.peer?.slots.find((slot) => slot.trackLabel === TrackLabel.ScreenVideo);
    try {
      const prevTracks = [this.screenVideoTrack, this.screenAudioTrack];
      const tracks = await this.createScreenSharingMediaTracks();

      if (!tracks.length) {
        return;
      }

      if (!screenSharingSlot && this.rootStore.roomStore.isDemoCountLimitExceed) {
        tracks.map((x) => x.mediaStreamTrack.stop());
        logger.error('Screen sharing error: Demo slot is already taken');
        return;
      }

      await Promise.all(prevTracks.map((track) => track?.unpublish()));

      if (!this.screenVideoTrack) {
        await this.disableScreensharing();

        return;
      }

      await Promise.all(tracks.map((track) => {
        if (track.kind === 'video') {
          const publishParams = this.getVideoTrackPublishParams();
          return track.publish(publishParams);
        }

        return track.publish();
      }));

      this.setIsScreenSharingDisabled(false);
    } catch (error) {
      if (screenSharingSlot && error.message && isPermissionErrorText(error.message as string)) {
        /* most likely user canceled screensharing */
        return;
      }

      logger.error('Failed to enable screensharing', {
        error,
        peerId: this.client.id,
        case: 'enableScreensharing',
      });

      this.rootStore.uiStore.setScreensharingToggleError(error instanceof Error ? error : new Error(String(error)));
      await this.disableScreensharing();
    } finally {
      this.isEnableScreenSharingLock = false;
      this.setIsScreenSharingChanging(false);
    }
  }

  @action async disableScreensharing(): Promise<void> {
    if (this.isEnableScreenSharingLock) {
      return;
    }

    this.isEnableScreenSharingLock = true;

    try {
      const tracks: Track[] = [];
      if (this.screenVideoTrack) {
        tracks.push(this.screenVideoTrack);
      }

      if (this.screenAudioTrack) {
        tracks.push(this.screenAudioTrack);
      }

      await Promise.all(tracks.map((track) => track.unpublish()));
      this.setScreenVideoTrack(undefined);
      this.setScreenAudioTrack(undefined);
      this.setIsScreenSharingDisabled(true);
    } catch (error) {
      logger.error('Failed to disable screensharing', {
        error,
        peerId: this.client.id,
        case: 'disableScreensharing',
      });
    } finally {
      this.isEnableScreenSharingLock = false;
    }
  }

  @action async deleteCameraPreviewTrack(): Promise<void> {
    if (this.cameraPreview) {
      this.setIsEnableVideoLock(true);
      await this.client.deleteTrack(this.cameraPreview);
      this.cameraPreview = undefined;
      this.setIsEnableVideoLock(false);
    }
  }

  @action async deleteCameraTrack(): Promise<void> {
    if (this.cameraTrack) {
      await this.client.deleteTrack(this.cameraTrack);
      this.cameraTrack = undefined;
    }
  }

  @action async deleteDevicesPreviewTrack(): Promise<void> {
    await this.deleteCameraPreviewTrack();

    if (this.cameraTrack?.isPublished) {
      return;
    }

    await this.deleteCameraTrack();
  }

  updateCameraSlotTransformParams(): void {
    this.peer?.appData.setTransformParams({
      videoIncline: this.videoIncline,
      videoFlipH: this.videoFlipH,
      videoFlipV: this.videoFlipV,
    });
  }

  @action setAudioDisabled(value: boolean): void {
    this.audioDisabled = value;
    setLocalStorageItem('audioDisabled', value);
  }

  @action setVideoDisabled(value: boolean): void {
    this.videoDisabled = value;
    setLocalStorageItem('videoDisabled', value);
  }

  @action setIsEnableVideoLock(value: boolean): void {
    this.isEnableVideoLock = value;
  }

  @action setIsEnableAudioLock(value: boolean): void {
    this.isEnableAudioLock = value;
  }

  @action reset(): void {
    this.cameraTrack = undefined;
    this.microphoneTrack = undefined;
    this.screenVideoTrack = undefined;
    this.screenAudioTrack = undefined;
    this.isScreenSharingDisabled = true;
  }

  get role(): Role {
    const { roomStore } = this.rootStore;

    return this.isWebinarHost || (roomStore.type === 'lesson')
      ? PeerRole.Host
      : PeerRole.Audience;
  }

  get roomPermissions() {
    return this._roomPermissions;
  }

  @computed
  get isSpeaker() {
    return this.roomPermissions?.isSpeaker;
  }

  @computed
  get isWebinarHost() {
    return Boolean(
      (this.rootStore.roomStore.type === 'webinar') && (this.isModerator || this.isSpeaker),
    );
  }

  setRoleInRoom(role: RoomRole, roomType: RoomType) {
    this._roomPermissions = new RoomPermissions(role, roomType);
  }

  useCameraSlot():void {
    if (!this.peer) {
      return;
    }

    try {
      this.peer.appData.setTransformParams({
        videoIncline: this.videoIncline,
        videoFlipH: this.videoFlipH,
        videoFlipV: this.videoFlipV,
      });
    } catch (error) {
      logger.error('Failed to use camera slot', {
        peerId: this.peer.id,
        case: 'useCameraSlot',
        error,
      });
    }
  }

  // This method is temporal and should be removed if no such log records will be caught
  // since April, 1 till May, 30
  private logAudioStatusChangeAttemptForNonHost(enable: boolean) {
    if (this.role !== PeerRole.Host) {
      logger.warn('Detected audio status change attempt for non host participant', {
        enable,
        participantId: this.id,
      });
    }
  }

  @action private async pauseVideo(): Promise<void> {
    if (!this.cameraTrack) {
      return;
    }

    await this.cameraTrack.pause(); // video effects is disabled in pause method
    this.setVideoDisabled(true);
    this.rootStore.metrics.trackCameraInUse(false);
  }

  @action private async resumeVideo(): Promise<void> {
    if (!this.cameraTrack) {
      return;
    }

    await this.cameraTrack.resume();
    this.setVideoDisabled(false);
    this.rootStore.metrics.trackCameraInUse(true);
  }

  @action async publishNewCameraTrack(): Promise<void> {
    const track = await this.createCameraTrack();
    if (!track) {
      throw new Error('Can not create camera track');
    }

    const publishParams = this.getVideoTrackPublishParams();
    await track.publish(publishParams);
    this.setCameraTrack(track);
    this.setVideoDisabled(false);
    this.rootStore.metrics.trackCameraInUse(true);
  }

  @action async unPublishCameraTrack(): Promise<void> {
    if (!this.cameraTrack) {
      return;
    }

    await this.cameraTrack.unpublish();
    this.setVideoDisabled(true);
    this.setCameraTrack(undefined);
    this.rootStore.metrics.trackCameraInUse(false);
  }

  async republishCameraTrack(): Promise<void> {
    try {
      this.setIsEnableVideoLock(true);
      await this.unPublishCameraTrack();
      await this.publishNewCameraTrack();
    } catch (error) {
      logger.error('Failed to republish camera track', {
        peerId: this.client.id,
        case: 'republishCameraTrack',
        error,
      });
    } finally {
      this.setIsEnableVideoLock(false);
    }
  }

  @action setCameraTrack(track?: VideoTrack): void {
    this.cameraTrack = track;
  }

  @action setMicrophoneTrack(track?: AudioTrack): void {
    this.microphoneTrack = track;
  }

  @action setCameraPreviewTrack(track?: VideoTrack): void {
    this.cameraPreview = track;
  }

  @action async pauseAudio(): Promise<void> {
    if (!this.microphoneTrack) {
      return;
    }

    await this.microphoneTrack.pause();
    this.setAudioDisabled(true);
    this.rootStore.metrics.trackMicrophoneInUse(false);
  }

  @action async resumeAudio(): Promise<void> {
    if (!this.microphoneTrack) {
      return;
    }

    await this.microphoneTrack.resume();
    this.setAudioDisabled(false);
    this.rootStore.metrics.trackMicrophoneInUse(true);
  }

  @action async publishNewMicrophoneTrack(): Promise<void> {
    const track = await this.createMicrophoneAudioTrack();
    if (!track) {
      throw new Error('Can not create microphone track');
    }

    await track.publish();
    this.setMicrophoneTrack(track);
    this.setAudioDisabled(false);
    this.rootStore.metrics.trackMicrophoneInUse(true);
  }

  @action async unPublishMicrophoneTrack(): Promise<void> {
    if (!this.microphoneTrack) {
      return;
    }

    await this.microphoneTrack.unpublish();
    this.setAudioDisabled(true);
    this.rootStore.metrics.trackMicrophoneInUse(false);
    this.setMicrophoneTrack(undefined);
  }

  @action setInboundMOS(mos: number): void {
    this.inboundMOS = {
      time: Date.now(),
      value: mos,
    };
  }

  @action setOutboundMOS(mos: number): void {
    this.outboundMOS = {
      time: Date.now(),
      value: mos,
    };
  }

  @action handleWebrtcIssues(issues: { type: string, reason: string }[]) {
    issues.forEach((issue) => {
      logger.info('WebRTCIssue', issue);
    });
  }

  @action setRedirectUrl(search?: string) {
    if (!search) {
      return;
    }
    const { redirectURL } = searchParamsToObject(search, {
      ignoreQueryPrefix: true,
      convertStrings: true,
    });

    if (!redirectURL) {
      return;
    }

    if (isValidHttpUrl(redirectURL as string)) {
      this.redirectURL = redirectURL as string;
    }
  }

  handleUpdatedNetworkScores(networkScores: NetworkScores) {
    const {
      inbound,
      outbound: outboundOriginal,
      statsSamples: { inboundStatsSample, outboundStatsSample },
    } = networkScores;
    const nodeActiveStreamsStat = this.client.getNodeActiveStreamsStat();
    const node = (nodeActiveStreamsStat.node)?.replace(/https:\/\/app-\d+-/, '');

    if (inbound !== undefined) {
      // round, because we don't need extra precision (no re-renders)
      this.setInboundMOS(Math.round(inbound * 10) / 10);
      if (inboundStatsSample) {
        this.networkScoresBatcher.Queue({
          jitter: Math.round(inboundStatsSample.avgJitter * 1000),
          packetLoss: inboundStatsSample.packetsLoss,
          rtt: inboundStatsSample.rtt,
          mos: inbound,
          streams: nodeActiveStreamsStat.inboundActiveStreams,
          direction: 'inbound',
          node,
          timestamp: (new Date()).toISOString(),
        } as NetworkMetric);
      }
    }

    if (outboundOriginal !== undefined) {
      const outbound = Math.round(outboundOriginal * 10) / 10;
      this.setOutboundMOS(outbound);
      if (outboundStatsSample) {
        this.networkScoresBatcher.Queue({
          jitter: Math.round(outboundStatsSample.avgJitter * 1000),
          packetLoss: outboundStatsSample.packetsLoss,
          rtt: outboundStatsSample.rtt,
          mos: outbound,
          streams: nodeActiveStreamsStat.outboundActiveStreams,
          direction: 'outbound',
          node,
          timestamp: (new Date()).toISOString(),
        } as NetworkMetric);
      }

      if (outbound <= MosConnectionQuality.BAD) {
        this.peer?.appData.setOutboundConnectionQuality(OutboundConnectionQuality.Bad);
      } else if (outbound <= MosConnectionQuality.POOR) {
        this.peer?.appData.setOutboundConnectionQuality(OutboundConnectionQuality.Poor);
      } else {
        this.peer?.appData.setOutboundConnectionQuality(OutboundConnectionQuality.Good);
      }
    }
  }

  getVideoTrackPublishParams(): VideoTrackPublishParams {
    if (this.rootStore.roomStore.isWebinar) {
      return {
        keyFrameRequestDelay: 3000,
      };
    }

    return {};
  }

  @computed get isWebinarGuest(): boolean {
    return !this.isWebinarHost;
  }

  @computed get isHandRaised(): boolean {
    return getIsHandRaised(this.participantAppData);
  }

  @action setInitialJoinApproval(): void {
    const notNeededApprove = this.rootStore.roomStore.waitingRoomAudience === WaitingRoomAudience.Nobody
      || this.isModerator;
    this.participantAppData.setJoinApproval(notNeededApprove ? JoinApproval.NotNeeded : JoinApproval.Waiting);
  }

  @computed get isJoinApproved(): boolean {
    if (this.rootStore.roomStore.isBreakoutRoom) {
      return true;
    }

    const { joinApproval } = this.participantAppData;
    return joinApproval === JoinApproval.Approved || joinApproval === JoinApproval.NotNeeded;
  }

  @computed get cameraEncoderConfig(): EncoderConfig {
    return this.videoConfig.cameraConfig.encoderConfig;
  }

  @computed get screenSharingEncoderConfig(): EncoderConfig {
    return this.videoConfig.screenSharingConfig.encoderConfig;
  }

  get cameraTrackDefaultConstrains(): MediaTrackConstraints {
    const { width, height } = this.videoConfig?.cameraConfig?.options || {};
    const { encodings = [] } = this.cameraEncoderConfig;

    return {
      width: { ideal: width },
      height: { ideal: height },
      frameRate: { ideal: Math.max(...encodings.map((enc) => enc.maxFramerate || 1)) },
    };
  }

  get screenSharingTrackDefaultConstrains(): MediaTrackConstraints {
    const { width, height } = this.videoConfig?.screenSharingConfig?.options || {};
    const { encodings = [] } = this.screenSharingEncoderConfig;

    return {
      width: {
        // Это костыль. Если мы передаем пустые constrains в safari он подхватывает fullHD
        ...!isSafari() && { max: Math.min(width, window.screen?.width || width) },
      },
      height: {
        ...!isSafari() && { max: Math.min(height, window.screen?.height || height) },
      },
      frameRate: { ideal: Math.max(...encodings.map((enc) => enc.maxFramerate || 1)) },
    };
  }

  async enableMicNoiseSuppression(): Promise<void> {
    const { microphoneTrack: track } = this;
    if (!track || !('enableNoiseSuppression' in track) || track.isPaused) {
      return;
    }

    await track.enableNoiseSuppression();
  }

  async disableMicNoiseSuppression(): Promise<void> {
    const { microphoneTrack: track } = this;
    if (!track || !('disableNoiseSuppression' in track) || track.isPaused) {
      return;
    }

    await track.disableNoiseSuppression();
  }

  async enableCameraEffectsOnResume(): Promise<void> {
    try {
      await this.enableCameraEffects();
    } catch (error) {
      logger.warn('Failed to enable effects on resume', {
        error,
        peerId: this.client.id,
        case: 'enableVideo',
      });
    }
  }

  async enableCameraEffects(): Promise<void> {
    // temporary until fix effects
    if (isMobile()) {
      return;
    }

    const { cameraTrack: track } = this;
    if (!track || track.isPaused) {
      return;
    }

    const { effects } = this.userSettings;
    if (effects) {
      await track.applyEffects(effects);
    }
  }

  async disableCameraEffects(): Promise<void> {
    const { cameraTrack: track } = this;
    if (!track || track.isPaused) {
      return;
    }

    await track.disableEffects();
  }

  @computed get isVisualEffectsEnabled(): boolean {
    return this.isVisualEffectsAvailable;
  }

  @computed get isNoiseSuppressionEnabled(): boolean {
    return this.isNoiseSuppressionAvailable && Boolean(getLocalStorageItem(NOISE_SUPPRESSION_LS_KEY));
  }

  @computed get isVisualEffectsAvailable(): boolean {
    if (isHeadlessChrome()) {
      return false;
    }

    // temporary until effects fix
    if (isMobile()) {
      return false;
    }

    return isChromeBasedBrowser() || isSafari();
  }

  @computed get isNoiseSuppressionAvailable(): boolean {
    return isChromeBasedBrowser()
      && !!this.rootStore.paymentStore?.currentPlan?.features.noiseSuppression && this.client.canUseNoiseSuppression;
  }

  @action setIsScreenSharingChanging(value: boolean) {
    this.isScreenSharingChanging = value;
  }

  @action setIsScreenSharingDisabled(value: boolean) {
    this.isScreenSharingDisabled = value;
  }

  async handleNetworkMetrics(metrics: NetworkMetric[]) {
    const roomId = this.rootStore.roomStore.id;
    if (!roomId) {
      return;
    }

    await this.rootStore.moodHoodAnalyticsApiClient.analytics.sendNetworkMetrics({
      metrics,
      clientMeta: {
        roomId,
        clientUniqueId: getClientUniqueId(),
      },
    });
  }

  get transformParams(): TransformParams {
    return {
      videoIncline: this.videoIncline,
      videoFlipH: this.videoFlipH,
      videoFlipV: this.videoFlipV,
    };
  }

  async handleRejoinRequired(): Promise<void> {
    if (this.rootStore.roomStore.isRoomReJoining) {
      return;
    }

    let retryCount = 0;
    while (retryCount < 5) {
      try {
        const rejoinAction = this.rootStore.participantStore.isRecorder
          ? this.rootStore.roomStore.reJoinRecorder()
          : this.rootStore.roomStore.reJoinParticipant(ReJoinReason.Reconnect);
        await rejoinAction; // eslint-disable-line no-await-in-loop
        return;
      } catch (err) {
        await new Promise((resolve) => { // eslint-disable-line no-await-in-loop
          setTimeout(resolve, 1000);
        });
        retryCount += 1;
      }
    }
  }
}

export default ParticipantStore;
