import React from 'react';
import { LinearProgressBody, LinearProgressValue } from './LinearProgressLayout';

const LinearProgress: React.FunctionComponent<{ value: number }> = ({ value }) => (
  <LinearProgressBody>
    {value > 0 && (
      <LinearProgressValue style={{ width: `min(${value}%, 100%)` }} />
    )}
  </LinearProgressBody>
);

export default LinearProgress;
