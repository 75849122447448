import React from 'react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { saveAs } from 'file-saver';
import { ObjectID } from 'bson';
import { format } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';
import i18n from 'services/i18n';

import PeerStore from 'stores/peer';
import { PeerInfo, ExecuteWithRndDelayPayload } from 'types/common';
import { AccessToken } from 'services/MoodHoodApiClient/AuthApi.types';
import { PeerAppData } from 'types/stores/peer';
import { isIOS } from 'helpers/browser';

import { getLocalStorageItem, setLocalStorageItem } from './localStorage';
import { getSessionStorageItem, setSessionStorageItem } from './sessionStorage';

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context#extended-example
export function createCtx<ContextType>(): [() => ContextType, React.Provider<ContextType | undefined>] {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) {
      throw new Error('useCtx must be inside a Provider with a value');
    }

    return c;
  }
  return [useCtx, ctx.Provider];
}

export const getVideoTransform = (videoIncline?: number, videoFlipH?: boolean, videoFlipV?: boolean): string => {
  const transforms: string[] = [];

  if (videoIncline && videoIncline !== 0) {
    transforms.push(`rotate(${videoIncline}deg)`);
  }

  if (videoFlipH) {
    transforms.push('scaleX(-1)');
  }

  if (videoFlipV) {
    transforms.push('scaleY(-1)');
  }

  return transforms.join(' ').trim();
};

export const isDateExpired = (expDate?: number) => (expDate || 0) <= Math.floor(Date.now() / 1000);

export const decodeAccessToken = (accessToken: string): AccessToken => {
  try {
    const accessTokenDecoded = jwt_decode<JwtPayload & { type?: string, coId?: string }>(accessToken);
    if (!accessTokenDecoded || typeof accessTokenDecoded !== 'object') {
      return {};
    }

    if (!accessTokenDecoded.exp) {
      return {};
    }

    const isExpired = isDateExpired(accessTokenDecoded.exp);

    if (isExpired) {
      return {};
    }

    return {
      type: accessTokenDecoded.type,
      aud: accessTokenDecoded.aud?.toString(),
      ownerId: accessTokenDecoded.sub,
      expiredAt: accessTokenDecoded.exp,
      issuedAt: accessTokenDecoded.iat,
      issuer: accessTokenDecoded.iss,
      jti: accessTokenDecoded.jti,
      coId: accessTokenDecoded.coId,
    };
  } catch (err) {
    return {};
  }
};

export const preloadImage = async (...urls: (string | null | undefined)[]): Promise<void> => {
  const promises = urls
    .filter((url) => !!url)
    .map((url): Promise<void> => new Promise(
      (resolve, reject) => {
        const img = new Image();
        img.src = url || '';
        img.onload = () => {
          resolve();
        };
        img.onerror = () => {
          reject();
        };
      },
    ));

  await Promise.allSettled(promises);
};

export const getUserInitials = (name: string) => name?.split(/(\s+)/)
  .filter((word) => /\S/.test(word))
  .slice(0, 2)
  .map((word) => word[0])
  .join('')
  .toUpperCase();

export const getColorFromString = (colors: string[], str?: string | null): string => {
  if (!str?.length || !colors.length) {
    return '#fff';
  }

  const index = str.length <= colors.length
    ? str.length - 1
    : str.length % (colors.length * Math.floor(str.length / colors.length));

  return colors[index];
};

const CLIENT_UID_STORAGE_KEY = 'clientUniqueId';
let fallbackClientUid: string | undefined; // in case local storage is disabled

export const generateAndSetClientUniqueId = (): string => {
  if (fallbackClientUid) {
    return fallbackClientUid;
  }

  const existingClientUniqueId = getLocalStorageItem<string>(CLIENT_UID_STORAGE_KEY);

  if (existingClientUniqueId) {
    fallbackClientUid = existingClientUniqueId;
    return existingClientUniqueId;
  }

  const clientUniqueId = new ObjectID().toString();
  fallbackClientUid = clientUniqueId;
  setLocalStorageItem(CLIENT_UID_STORAGE_KEY, clientUniqueId);

  return clientUniqueId;
};

export const getClientUniqueId = (): string => {
  const id = getLocalStorageItem<string>(CLIENT_UID_STORAGE_KEY);

  if (id && ObjectID.isValid(id)) {
    return id;
  }

  return fallbackClientUid ?? generateAndSetClientUniqueId();
};

let fallbackTabId: string | undefined; // in case session storage is disabled

export const getClientTabId = (): string => {
  if (fallbackTabId) {
    return fallbackTabId;
  }

  const keyName = 'ld_ct_id';
  let tabId = getSessionStorageItem<string | null>(keyName);

  if (!tabId) {
    tabId = `${Math.random().toString(32).substr(2)}_${Date.now()}`;
    fallbackTabId = tabId;
    setSessionStorageItem(keyName, tabId);
  }

  return tabId;
};

export const clamp = (
  numToClamp: number,
  lower: number,
  upper: number,
): number => Math.max(lower, Math.min(numToClamp, upper));

export const hasChanges = (
  oldData: Record<string, unknown>, newData: Record<string, unknown>,
): boolean => {
  const oldKeys = Object.keys(oldData);
  const newKeys = Object.keys(newData);
  if (oldKeys.length !== newKeys.length) {
    return true;
  }

  return oldKeys.some((key) => oldData[key] !== newData[key]); /* shallow comparison */
};

export const objectsDiff = <T extends object>(obj1: T, obj2: T): Partial<T> => {
  const diff: Partial<T> = {};
  let k: keyof T;
  // eslint-disable-next-line no-restricted-syntax
  for (k in obj1) {
    if (obj1[k] !== obj2[k]) {
      diff[k] = obj2[k];
    }
  }

  return diff;
};

export const shuffleArray = <T>(array: T[]): T[] => {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }

  return result;
};

export const isObjectEmpty = <T extends object>(obj: T) => Object.keys(obj).length === 0;

export const downloadJSON = (json: Record<string, unknown>, fileName: string): void => {
  const file = new Blob([JSON.stringify(json, null, 2)], { type: 'text/plain' });
  saveAs(file, fileName);
};

export const sortPeerStoreByUID = (a: PeerStore, b: PeerStore): number => {
  const aUid = a.engine.uid || '';
  const bUid = b.engine.uid || '';
  return aUid.localeCompare(bUid);
};

export const sortPeerInfoByUID = (a: PeerInfo, b: PeerInfo): number => sortPeerStoreByUID(a.peer, b.peer);

export const sortPeerInfoByUIDAndPeerId = (a: PeerInfo, b: PeerInfo): number => {
  const sortByUID = sortPeerInfoByUID(a, b);
  return sortByUID === 0 ? a.peer.id.localeCompare(b.peer.id) : sortByUID;
};

export const receiveClientTrackingId = (): string => getClientUniqueId();

export function* chunk<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const getIsHandRaised = (appData?: PeerAppData): boolean => !!(appData?.isHandRaised || appData?.handRaisedAt);

export const getHoursAndMinutesTime = (date: Date | string | number) => {
  const d = new Date(date);
  return format(d, 'HH:mm');
};

export const getCombinationOfDateAndTime = (date?: Date | string | number) => {
  const d = date ? new Date(date) : new Date();
  const locale = i18n.language === 'ru' ? ru : enUS;
  return format(d, 'Pp', { locale });
};

export const isWhiteLabelFromURL = () => {
  const sp = new URLSearchParams(window.location.search.toLocaleLowerCase());
  return sp.get('iswhitelabel') === '1';
};

export const toWindowFeatures = (obj: ({ [i: string]: (number | string) } | undefined) = {}) => Object.keys(obj)
  .reduce((features, name) => {
    const value = obj[name];
    if (typeof value === 'boolean') {
      features?.push(`${name}=${value ? 'yes' : 'no'}`);
    } else {
      features?.push(`${name}=${value}`);
    }
    return features;
  }, [] as string[])
  .join(',');

export const toCssPx = (value: number | string | undefined | null): string | undefined => {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  if (typeof value === 'string') {
    return value;
  }

  return undefined;
};

export const isCorrectChatNewWindowOpener = (aliasOrId: string): boolean => {
  const opener = (window.opener as Window | null);

  if (!opener || opener?.closed) {
    return false;
  }

  const { pathname } = opener.location;

  const isRoom = pathname.startsWith(`/room/${aliasOrId}`);
  const isEvent = pathname.startsWith(`/event/${aliasOrId}`);
  const isEventPreview = pathname.startsWith('/space/') && pathname.endsWith(`/${aliasOrId}/preview`);

  if (!isRoom && !isEvent && !isEventPreview) {
    return false;
  }

  return true;
};

export const isiOSAppUserAgent = (userAgent?: string): boolean => {
  const isIosApp = /(^livedigital_ios\/.*\(pro.vlprojects.livedigital-(stage-)*app)/.test(userAgent || '');
  return isIosApp || false;
};

export const getAppDownloadLink = () => (isIOS
  ? 'https://apps.apple.com/app/livedigital/id1597698137'
  : 'https://play.google.com/store/apps/details?id=space.livedigital.app');

export const formatDateToLocaleFormat = (date: Date) => date
  .toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' });

export const datesToTimesInterval = (startDate: Date, finishDate?: Date) => {
  const startTime = getHoursAndMinutesTime(startDate);
  const finishTime = finishDate && getHoursAndMinutesTime(finishDate);

  return startTime + (finishTime ? ` — ${finishTime}` : '');
};

export const datesDiffInHoursMinutesSeconds = (startDate: Date, finishDate?: Date) => {
  let diff = Math.abs((finishDate || new Date()).getTime() - startDate.getTime());

  let days = 0;

  const secInDay = 24 * 3600 * 1000;

  if (diff > secInDay) {
    days = Math.floor(diff / secInDay);
    diff %= secInDay;
  }

  const date = new Date(diff);

  const hours = date.getUTCHours() + (days * 24);
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  return [hours, minutes, seconds].map((x) => String(x).padStart(2, '0')).join(':');
};

// eslint-disable-next-line arrow-body-style
export const isValidUserName = (username?: string, email?: string) => {
  return username && username.toLowerCase().trim() !== email?.toLowerCase();
};

export const executeWithRndDelay = (payload: ExecuteWithRndDelayPayload): Promise<unknown> => new Promise((resolve) => {
  const { maxDelayMs, callback } = payload;
  const timeoutDelay = Math.floor(Math.random() * maxDelayMs);
  setTimeout(() => {
    resolve(callback());
  }, timeoutDelay);
});

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const cronDaysOfWeekToString = (cronPattern: string, weekDays: string) => {
  const days = weekDays.toLocaleUpperCase().split(' ');
  const cronDaysPos = cronPattern.lastIndexOf('*');
  if (cronDaysPos !== -1) {
    const cronDays = cronPattern
      .substring(cronDaysPos + 1)
      .trim()
      .split(',')
      .map(Number);

    const daysString = cronDays
      // fix days of week [0, 1, 2, 3, 4, 5, 6] to [1, 2, 3, 4, 5, 6, 7]
      .map((dayIndex) => (dayIndex || days.length) - 1)
      .sort((a, b) => a - b)
      .map((x) => days[x])
      .join(', ');

    return daysString;
  }

  return null;
};

export const sanitizeAnalyticsPhoneNumber = (phone?: string): string => (phone && phone.length > 4 ? phone : '');

export const isElectronAppUserAgent = () => Boolean(navigator.userAgent.match(/(livedigital|teleboss)\/.*Electron\//));
