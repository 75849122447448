import Client from '@livedigital/client';
import { PEER_EVENTS } from '@livedigital/client/dist/constants/events';
import Peer from '@livedigital/client/dist/engine/Peer';
import { action, makeAutoObservable } from 'mobx';
import {
  RoomRole,
  TransformParams,
} from 'services/MoodHoodApiClient/types';
import { PeerAppData } from 'types/stores/peer';
import { JoinApproval, OutboundConnectionQuality } from 'types/stores/peerAppData';
import PeerStore from './peer';

type PeerAppDataStoreConstructorParams = {
  peerStore: PeerStore;
  client: Client;
};

class PeerAppDataStore {
  private peer: Peer;

  private client: Client;

  userId?: string;

  roomId?: string;

  spaceId?: string;

  isModerator?: boolean;

  isNoCam?: boolean;

  isNoMic?: boolean;

  detectDevicesError?: string;

  outboundConnectionQuality?: OutboundConnectionQuality;

  isOutOfScreen?: boolean;

  joinApproval?: JoinApproval;

  isHandRaised?: boolean;

  handRaisedAt?: number | null;

  clientUniqueId?: string;

  isScreensharingPeer?: boolean;

  isBroadcaster?: boolean;

  userAgent?: string;

  isVideoStreamingAllowed?: boolean;

  isAudioStreamingAllowed?: boolean;

  transformParams?: TransformParams;

  peerStore: PeerStore;

  name?: string;

  image?: string;

  roleInRoom?: RoomRole;

  constructor({ peerStore, client }: PeerAppDataStoreConstructorParams) {
    this.peer = peerStore.engine;
    this.client = client;
    this.peerStore = peerStore;

    this.handleUpdatedAppData(this.peer.appData as PeerAppData);
    this.peer.observer.on(PEER_EVENTS.appDataUpdated, (payload: PeerAppData) => {
      this.handleUpdatedAppData(payload);
    });

    makeAutoObservable(this);
  }

  async setIsNoCam(value = false): Promise<void> {
    await this.setAttribute('isNoCam', value);
  }

  async setIsNoMic(value = false): Promise<void> {
    await this.setAttribute('isNoMic', value);
  }

  async setDetectDevicesError(value = ''): Promise<void> {
    await this.setAttribute('detectDevicesError', value);
  }

  async setOutboundConnectionQuality(value = OutboundConnectionQuality.Good): Promise<void> {
    await this.setAttribute('outboundConnectionQuality', value);
  }

  setIsHandRaised(value: number | null): void {
    this.setAttributes({
      isHandRaised: Boolean(value), // legacy for backward compatibility
      handRaisedAt: value,
    });
  }

  async setTransformParams(value: TransformParams): Promise<void> {
    await this.setAttribute('transformParams', value);
  }

  async setIsOutOfScreen(value: boolean): Promise<void> {
    await this.setAttribute('isOutOfScreen', value);
  }

  async setCanEnterRoom(value: JoinApproval): Promise<void> {
    await this.setAttribute('joinApproval', value);
  }

  async setImage(value: string | null): Promise<void> {
    await this.setAttribute('image', value);
  }

  async setName(value: string): Promise<void> {
    await this.setAttribute('name', value);
  }

  @action private handleUpdatedAppData(appData: PeerAppData) {
    this.userId = appData.userId;
    this.roomId = appData.roomId;
    this.spaceId = appData.spaceId;
    this.isModerator = appData.isModerator;
    this.isNoCam = appData.isNoCam;
    this.isNoMic = appData.isNoMic;
    this.detectDevicesError = appData.detectDevicesError;
    this.outboundConnectionQuality = appData.outboundConnectionQuality;
    this.isOutOfScreen = appData.isOutOfScreen;
    this.joinApproval = appData.joinApproval;
    this.isHandRaised = appData.isHandRaised;
    this.handRaisedAt = appData.handRaisedAt;
    this.clientUniqueId = appData.clientUniqueId;
    this.isScreensharingPeer = appData.isScreensharingPeer;
    this.userAgent = appData.userAgent;
    this.isBroadcaster = appData.isBroadcaster;
    this.transformParams = appData.transformParams;
    this.name = appData.name;
    this.image = appData.image;
    this.isAudioStreamingAllowed = appData.isAudioStreamingAllowed;
    this.isVideoStreamingAllowed = appData.isVideoStreamingAllowed;
    this.roleInRoom = appData.roleInRoom;
  }

  getCurrentAppData(): PeerAppData {
    return {
      userId: this.userId,
      roomId: this.roomId,
      spaceId: this.spaceId,
      isModerator: this.isModerator,
      isNoCam: this.isNoCam,
      isNoMic: this.isNoMic,
      detectDevicesError: this.detectDevicesError,
      outboundConnectionQuality: this.outboundConnectionQuality,
      isOutOfScreen: this.isOutOfScreen,
      joinApproval: this.joinApproval,
      isHandRaised: this.isHandRaised,
      handRaisedAt: this.handRaisedAt,
      clientUniqueId: this.clientUniqueId,
      isScreensharingPeer: this.isScreensharingPeer,
      userAgent: this.userAgent,
      isBroadcaster: this.isBroadcaster,
      transformParams: this.transformParams,
      name: this.name,
      image: this.image,
      isAudioStreamingAllowed: this.isAudioStreamingAllowed,
      isVideoStreamingAllowed: this.isVideoStreamingAllowed,
      roleInRoom: this.roleInRoom,
    };
  }

  async setAttributes(attributes: Partial<PeerAppData>): Promise<void> {
    const newAppData = {
      ...this.getCurrentAppData(),
      ...attributes,
    };

    await this.client.updateAppData(newAppData);
    this.handleUpdatedAppData(newAppData);
  }

  async setAttribute(key: keyof PeerAppData, value?: unknown): Promise<void> {
    const currentAppData = this.getCurrentAppData();
    if (currentAppData[key] === value) {
      return;
    }

    const newAppData = {
      ...currentAppData,
      [key]: value,
    };

    await this.client.updateAppData(newAppData);
    this.handleUpdatedAppData(newAppData);
  }
}

export default PeerAppDataStore;
