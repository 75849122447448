import styled from '@emotion/styled';

import { getObjectTextStyle } from 'ui-kit/theme';

import { LabelPosition } from './index';

export const RadioButtonInput = styled.input(({ theme: { colors } }) => ({
  appearance: 'none',
  display: 'block',
  width: 24,
  height: 24,
  border: `2px solid ${colors.secondary03}`,
  borderRadius: '50%',
  '&:hover:not([disabled]):not(:focus)': {
    borderWidth: 6,
  },

  '&[disabled]': {
    borderColor: colors.secondary02,
  },

  '&:focus:hover': {
    borderColor: colors.accentBase,
    borderWidth: 2,
  },

  '&:checked': {
    backgroundColor: colors.accentBase,
    border: 'none',
    outline: `4px solid ${colors.bg}`,
    outlineOffset: -6,
    '&:hover:not([disabled]):not(:focus)': {
      backgroundColor: colors.accent03,
    },
    '&[disabled]': {
      backgroundColor: colors.secondary02,
    },
  },

  flexShrink: 0,
}));

export const RadioButtonRoot = styled.label<{ labelPosition: LabelPosition }>(({
  labelPosition,
  theme: { colors },
}) => ({
  ...getObjectTextStyle('main-text-medium'),
  color: colors.secondaryBase,
  position: 'relative',
  display: 'flex',
  flexDirection: labelPosition === 'left' ? 'row-reverse' : 'row',
  alignItems: 'flex-start',
  width: 'max-content',
  userSelect: 'none',
  '& > input:first-of-type': {
    margin: labelPosition === 'left' ? '0 0px 0 8px' : '0 8px 0 0px',
  },
}));

export const LoaderLayout = styled.label({
  marginRight: 16,
});
