import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { ToastContainer } from 'react-toastify';
import { Theme } from '@emotion/react';

import {
  Font, Size, MediaBreakPoint,
} from 'ui-kit/theme';
import SuccessIcon from 'ui-kit/assets/icons/snackbar/success.svg';
import ErrorIcon from 'ui-kit/assets/icons/snackbar/error.svg';
import WarningIcon from 'ui-kit/assets/icons/snackbar/warning.svg';
import InfoWhite from 'ui-kit/assets/icons/snackbar/info-white.svg';
import InfoBlue from 'ui-kit/assets/icons/snackbar/info-blue.svg';
import { ThemeType } from 'ui-kit/themes/types';

const StyledToasts = styled(ToastContainer)<{ appTheme: Theme }>`
  ${MediaBreakPoint.WIDTH_640} {
    width: 100%;
    left: 0;
    transform: translateX(0);
  }

  .Toastify__toast {
    border: 2px solid ${({ appTheme }) => appTheme.colors.secondary02};
    box-sizing: content-box;
    border-radius: 15px;
    padding: 0;
    min-height: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .Toastify__toast-body {
    padding: 10px 10px 10px 42px;
    min-height: 56px;
    background: ${({ appTheme }) => appTheme.colors.primary};
    background-repeat: no-repeat;
    background-position: 10px center;
    color: ${({ appTheme }) => appTheme.colors.bg};
    font-family: ${Font.BRAND_BOLD};
    font-size: 16px;
    line-height: 20px;
  }

  .Toastify__toast--success > .Toastify__toast-body {
    background-image: url('${SuccessIcon}');
  }

  .Toastify__toast--error > .Toastify__toast-body {
    background-image: url('${ErrorIcon}');
  }

  .Toastify__toast--warning > .Toastify__toast-body {
    background-image: url('${WarningIcon}');
  }

  .Toastify__toast--info > .Toastify__toast-body {
    background-image: url('${({ appTheme }) => (appTheme.name === ThemeType.dark ? InfoBlue : InfoWhite)}');
  }
`;

export const StyledCommonSnackbarContainer = styled(StyledToasts)<{ isPanelsShown: boolean }>`
  width: auto;
  left: 0;
  bottom: ${({ isPanelsShown }) => (isPanelsShown ? Size.CONTROLPANEL_HEIGHT + 8 : 8)}px;
  padding: 0 8px;
  transition: bottom 700ms;

  ${MediaBreakPoint.HEIGHT_900} {
    bottom: ${({ isPanelsShown }) => (isPanelsShown ? Size.CONTROLPANEL_HEIGHT_960 + 8 : 8)}px;
  }
`;

export const withWidthClassName = css`
  background-position: 10px 10px !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${MediaBreakPoint.WIDTH_1280} {
    flex-direction: column;
  }
`;
